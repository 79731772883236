body {
  margin: 0;
  padding: 0;
  background-color: #283041;
  color: white;
  font-size: 10px;
}
main, footer {
  position: relative;
  z-index: 1;
}
.particles {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100vw;
}

/* Header */
.App-header {
  position: relative;
  z-index: 1;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header>img {
  display: block;
  max-height: 40vh;
  max-width: 50vw;
  border-radius: 100%;
  margin: 20px auto;
}

main {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
}

main>h1 {
  display: inline-block;
  margin: 0 auto 1rem 1rem;
  font-size: 3.5rem;
  width: 15rem;
  text-align: right;
  left: 0;
}
main>h2 {
  font-size: 1.8rem;
  line-height: 1.5;
  opacity: .9;
  position: relative;
  width: 90%;
  max-width: 30rem;
  display: block;
  margin: 6rem 1rem;
}
@media(min-width: 800px) {
  main>h2 {
    left: 50%;
    transform: translateX(-75%);
  }
}

/* Southern Dev */
.SouthernDev {
  width: 100%;
  display: block;
}
.SouthernDev .image img {
  max-width: 40%;
  min-height: 50px;
  height: auto;
  margin: 0 auto;
  display: block;
}
.SouthernDev>p {
  font-size: 1.8rem;
  font-weight: bold;
  opacity: .7;
  max-width: 80%;
  margin: 3rem 1rem;
}
@media(min-width: 700px) {
  .SouthernDev {
    width: 100%;
    display: flex;
  }
  .SouthernDev>.image {
    width: 60%;
  }
  .SouthernDev>.image>img {
    max-width: 40%;
    min-height: 50px;
    height: auto;
    margin: 0 auto;
    display: block;
  }
  .SouthernDev>p {
    font-size: 1.8rem;
    font-weight: bold;
    opacity: .8;
    max-width: 40%;
    margin: 3rem auto;
  }
}

/* Teaching */
.teaching {
  width: 100%;
  display: block;
}
.teaching .leftContainer {
  width: 100%;
  position: relative;
}
.teaching .orangeTriangle {
  position: relative;
  width: 0;
  height: 0;
  left: 50%;
  transform: translateX(-50%);
  border-top: 220px solid transparent;
  border-bottom: 170px solid transparent;
  border-left: 300px solid #FF5B02;
}
.teaching p {
  font-size: 1.8rem;
  position: absolute;
  top: 50px;
  left: 40%;
  max-width: 300px;
}
.teaching>.image {
  width: 80%;
  position: relative;
  z-index: 1;
  top: -60px;
}
.teaching>.image img {
  width: 100%;
  margin: 0 auto;
  display: block;
  clip-path: polygon(25% 10%, 100% 10%, 75% 80%, 0% 80%);
}
@media(min-width: 600px) {
  .teaching {
    width: 100%;
    display: flex;
  }
  .teaching .leftContainer {
    width: 50%;
    position: relative;
  }
  .teaching .orangeTriangle {
    position: absolute;
  }
  .teaching>.image {
    width: 50%;
    top: 0;
  }
}

/* Footer */
footer {
  width: 100%;
  display: block;
  position: relative;
}
ul{
  width: 60%;
  margin: 0;
  display: block;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 2rem auto;
  padding: 0;
}
li {
  width: 20%;
  min-width: 60px;
  padding: 20px 0;
  margin: 0;
  list-style-type: none;
} 
.fa-dev, .fa-twitter, .fa-github, .fa-codepen, .fa-linkedin {
  font-size: 2rem;
  color: white;
  /* text-shadow: 2px 2px rgba(255, 255,0); */
  transition: color .4s ease;
}
.fa-dev:hover, .fa-twitter:hover, .fa-github:hover, .fa-codepen:hover, .fa-linkedin:hover {
  color: #02A6FF;
} 

@media(min-width: 600px) {
  .fa-dev, .fa-twitter, .fa-github, .fa-codepen, .fa-linkedin {
    font-size: 3rem;
  }
}
