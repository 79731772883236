body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

body {
  color: #fff;
  background-color: #283041;
  margin: 0;
  padding: 0;
  font-size: 10px;
}

main, footer {
  z-index: 1;
  position: relative;
}

.particles {
  z-index: 0;
  width: 100vw;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.App-header {
  z-index: 1;
  color: #fff;
  font-size: calc(10px + 2vmin);
  position: relative;
}

.App-header > img {
  max-height: 40vh;
  max-width: 50vw;
  border-radius: 100%;
  margin: 20px auto;
  display: block;
}

main {
  max-width: 1600px;
  width: 100%;
  text-align: left;
  margin: 0 auto;
}

main > h1 {
  width: 15rem;
  text-align: right;
  margin: 0 auto 1rem 1rem;
  font-size: 3.5rem;
  display: inline-block;
  left: 0;
}

main > h2 {
  opacity: .9;
  width: 90%;
  max-width: 30rem;
  margin: 6rem 1rem;
  font-size: 1.8rem;
  line-height: 1.5;
  display: block;
  position: relative;
}

@media (min-width: 800px) {
  main > h2 {
    left: 50%;
    -webkit-transform: translateX(-75%);
    transform: translateX(-75%);
  }
}

.SouthernDev {
  width: 100%;
  display: block;
}

.SouthernDev .image img {
  max-width: 40%;
  min-height: 50px;
  height: auto;
  margin: 0 auto;
  display: block;
}

.SouthernDev > p {
  opacity: .7;
  max-width: 80%;
  margin: 3rem 1rem;
  font-size: 1.8rem;
  font-weight: bold;
}

@media (min-width: 700px) {
  .SouthernDev {
    width: 100%;
    display: flex;
  }

  .SouthernDev > .image {
    width: 60%;
  }

  .SouthernDev > .image > img {
    max-width: 40%;
    min-height: 50px;
    height: auto;
    margin: 0 auto;
    display: block;
  }

  .SouthernDev > p {
    opacity: .8;
    max-width: 40%;
    margin: 3rem auto;
    font-size: 1.8rem;
    font-weight: bold;
  }
}

.teaching {
  width: 100%;
  display: block;
}

.teaching .leftContainer {
  width: 100%;
  position: relative;
}

.teaching .orangeTriangle {
  width: 0;
  height: 0;
  border-top: 220px solid rgba(0, 0, 0, 0);
  border-bottom: 170px solid rgba(0, 0, 0, 0);
  border-left: 300px solid #ff5b02;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.teaching p {
  max-width: 300px;
  font-size: 1.8rem;
  position: absolute;
  top: 50px;
  left: 40%;
}

.teaching > .image {
  width: 80%;
  z-index: 1;
  position: relative;
  top: -60px;
}

.teaching > .image img {
  width: 100%;
  -webkit-clip-path: polygon(25% 10%, 100% 10%, 75% 80%, 0% 80%);
  clip-path: polygon(25% 10%, 100% 10%, 75% 80%, 0% 80%);
  margin: 0 auto;
  display: block;
}

@media (min-width: 600px) {
  .teaching {
    width: 100%;
    display: flex;
  }

  .teaching .leftContainer {
    width: 50%;
    position: relative;
  }

  .teaching .orangeTriangle {
    position: absolute;
  }

  .teaching > .image {
    width: 50%;
    top: 0;
  }
}

footer {
  width: 100%;
  display: block;
  position: relative;
}

ul {
  width: 60%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem auto;
  padding: 0;
  display: flex;
}

li {
  width: 20%;
  min-width: 60px;
  margin: 0;
  padding: 20px 0;
  list-style-type: none;
}

.fa-dev, .fa-twitter, .fa-github, .fa-codepen, .fa-linkedin {
  color: #fff;
  font-size: 2rem;
  transition: color .4s;
}

.fa-dev:hover, .fa-twitter:hover, .fa-github:hover, .fa-codepen:hover, .fa-linkedin:hover {
  color: #02a6ff;
}

@media (min-width: 600px) {
  .fa-dev, .fa-twitter, .fa-github, .fa-codepen, .fa-linkedin {
    font-size: 3rem;
  }
}

.wBP5ba_links {
  width: 80%;
  margin: 0 auto;
}

.wBP5ba_links ul {
  width: 100%;
  background: rgba(255, 148, 232, .69);
  border-radius: 10px;
  padding: 20px;
  display: block;
}

.wBP5ba_links li {
  width: 80%;
  max-width: 800px;
  background: rgba(0, 0, 0, .7);
  border-radius: 5px;
  margin: 10px auto;
  padding: 10px 20px;
  display: block;
  box-shadow: 0 0 20px #000;
}

.wBP5ba_links h2 {
  letter-spacing: .1rem;
  font-family: Haettenschweiler, Arial Narrow Bold, sans-serif;
  font-size: 2rem;
}

.wBP5ba_links h3 {
  text-underline-offset: .5rem;
  font-size: 1.3rem;
  text-decoration: underline;
}

.wBP5ba_links p, .wBP5ba_links a {
  font-size: 1rem;
}

.wBP5ba_links a {
  color: #0ff;
}

.wBP5ba_links a:visited {
  color: #ff1493;
}

/*# sourceMappingURL=index.b611c7f6.css.map */
